import { useState, useEffect, lazy, Suspense } from "react";

// react-router components
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  Outlet,
  useNavigate,
  // HashRouter as Router,
} from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import Sidenav from "examples/Sidenav";

import theme from "assets/theme";
// import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";

import { useMaterialUIController, setMiniSidenav /* , setOpenConfigurator */ } from "context";

import logo from "assets/images/identite/logo.png";

// import SignIn from "layouts/authentication/sign-in";
// import SignUp from "layouts/authentication/sign-up";
// import Error404 from "layouts/error404/Error404";
// import GestionLot from "layouts/donneeReference/rubrique/gestionLot";
import routesData from "router/routes";
import { LAST_PATH, USER_DATA_STORAGE } from "constant";
// import Logout from "layouts/authentication/sing-out";
// import GestionAnimaux from "layouts/gestionAnimaux";
import SuspenceComponent from "components/SuspenceComponent";
import LayoutWrapper from "components/LayoutWrapper";
// const SignUp = lazy(() => import("layouts/authentication/sign-up"));

import {
  PATH_LOT,
  PATH_ANIMAUX,
  PATH_RACE,
  PATH_DETAIL_USER,
  PATH_DETAIL_ANIMAL,
  PATH_DETAIL_DEMANDE_IDENTIFICATION,
  PATH_NEW_DEMANDE_IDENTIFICATION,
} from "constant/moduleInfo";
import DetailUser from "layouts/gestionUsers/rubrique/DetailUser";

import DetailAnimal from "layouts/gestionAnimaux/DetailAnimal";
import DetailDemandeIdentification from "layouts/gestionAnimaux/DetailDemandeIdentification";

import NewDemandeIdentification from "layouts/gestionAnimaux/NewDemandeIdentification";

// const Sidenav = lazy(() => import("examples/Sidenav"));
const SignIn = lazy(() => import("layouts/authentication/sign-in"));

const Error404 = lazy(() => import("layouts/error404/Error404"));
const GestionLot = lazy(() => import("layouts/donneeReference/rubrique/gestionLot"));
const GestionRace = lazy(() => import("layouts/donneeReference/rubrique/gestionRace"));
const Logout = lazy(() => import("layouts/authentication/sing-out"));
const GestionAnimaux = lazy(() => import("layouts/gestionAnimaux"));

const useAuth = () => {
  let userToken = null;
  if (localStorage.getItem(USER_DATA_STORAGE) !== null) {
    userToken = JSON.parse(localStorage.getItem(USER_DATA_STORAGE));
  }
  if (userToken !== null && userToken !== "" && userToken !== "{}" && userToken !== undefined) {
    // const auth_info = JSON.parse(userToken);
    const currentDate = new Date();
    if (userToken.exp * 1000 > currentDate.getTime()) {
      return true;
    }
    return false;
  }
  return false;
};
export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    // openConfigurator,
    sidenavColor,
    // transparentSidenav,
    // whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [defaultPath, setdefaultPath] = useState("/");
  // const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const [isAuth, setIsAuth] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const AuthBool = useAuth();
    setIsAuth(AuthBool);
  });
  useEffect(() => {
    let userToken = null;
    if (localStorage.getItem(USER_DATA_STORAGE) !== null) {
      userToken = JSON.parse(localStorage.getItem(USER_DATA_STORAGE));
    }
    const AuthBool = useAuth();
    if (AuthBool) {
      let userRoles = [];
      if (userToken !== null) {
        userRoles = userToken.roles;
      }
      const dataRes = [];
      routesData.forEach((i, idx, arr) => {
        const isAvalaible = i.role
          .map((el1) => ({
            name: el1,
            match: userRoles.some((el2) => el2.roleName.toLowerCase() === el1.toLowerCase()),
          }))
          .some((x) => x.match === true);
        if (isAvalaible) {
          dataRes.push(i);
        }
        if (idx === arr.length - 1) {
          return dataRes;
        }
        return "success";
      });
      // console.log("ddd999/// last path", dataRes);
      setdefaultPath(dataRes[0].route);
      const lastPath = localStorage.getItem(LAST_PATH);
      if (lastPath === null) {
        if (Array.isArray(dataRes) && dataRes.length > 0) {
          navigate(dataRes[0].route);
        }
      } else {
        navigate(lastPath);
      }
    }
  }, []);
  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      // console.log("getRoutes start", route.collapse);
      if (route?.collapse?.length > 0) {
        // console.log("getRoutes collapse", route.route);
        return getRoutes(route.collapse);
      }

      // if (route.route) {
      // console.log("getRoutes not ", route.route);
      return (
        <Route
          path={route.route}
          element={<LayoutWrapper>{route.component}</LayoutWrapper>}
          key={route.key}
        />
      );

      // return null;
    });

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && isAuth && (
        <Sidenav
          color={sidenavColor}
          brand={logo}
          // brand="assets/images/logo.png"
          // brandName="Beta Clic"
          // routes={routes}
          routes={routesData}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
          // userRoles={userRoles}
        />
      )}
      <Routes>
        <Route exact path="/" element={<ProtectedRoutes />}>
          {/* <Route path="/" element={<Navigate replace to="/dashboard" />} /> */}
          <Route path="/" element={<Navigate replace to={defaultPath} />} />
          {getRoutes(routesData)}
          <Route
            path="*"
            element={
              <Suspense fallback={<SuspenceComponent />}>
                <Error404 />
              </Suspense>
            }
          />
          <Route
            path="/logout"
            element={
              <Suspense fallback={<SuspenceComponent />}>
                <Logout />
              </Suspense>
            }
          />
          <Route
            path={PATH_LOT}
            element={
              <Suspense fallback={<SuspenceComponent />}>
                <LayoutWrapper>
                  <GestionLot />
                </LayoutWrapper>
              </Suspense>
            }
          />
          <Route
            path={PATH_ANIMAUX}
            element={
              <Suspense fallback={<SuspenceComponent />}>
                <LayoutWrapper>
                  <GestionAnimaux />
                </LayoutWrapper>
              </Suspense>
            }
          />
          <Route
            path={PATH_RACE}
            element={
              <Suspense fallback={<SuspenceComponent />}>
                <LayoutWrapper>
                  <GestionRace />
                </LayoutWrapper>
              </Suspense>
            }
          />
          <Route
            path={PATH_DETAIL_USER}
            element={
              <Suspense fallback={<SuspenceComponent />}>
                <LayoutWrapper>
                  <DetailUser />
                </LayoutWrapper>
              </Suspense>
            }
          />
          <Route
            path={PATH_DETAIL_ANIMAL}
            element={
              <Suspense fallback={<SuspenceComponent />}>
                <LayoutWrapper>
                  <DetailAnimal />
                </LayoutWrapper>
              </Suspense>
            }
          />
          <Route
            path={PATH_DETAIL_DEMANDE_IDENTIFICATION}
            element={
              <Suspense fallback={<SuspenceComponent />}>
                <LayoutWrapper>
                  <DetailDemandeIdentification />
                </LayoutWrapper>
              </Suspense>
            }
          />
          <Route
            path={PATH_NEW_DEMANDE_IDENTIFICATION}
            element={
              <Suspense fallback={<SuspenceComponent />}>
                <LayoutWrapper>
                  <NewDemandeIdentification />
                </LayoutWrapper>
              </Suspense>
            }
          />
        </Route>

        {/* <Route path="*" element={<Navigate to="/dashboard" />} /> */}
        <Route path="" element={<PublicRoutes />}>
          <Route
            path="/sign-in"
            element={
              <Suspense fallback={<SuspenceComponent />}>
                <SignIn />{" "}
              </Suspense>
            }
          />
          {/* <Route
            path="/sign-up"
            element={
              <Suspense fallback={<div>...</div>}>
                <SignUp />{" "}
              </Suspense>
            }
          /> */}
        </Route>
      </Routes>
      {/* </Router> */}
    </ThemeProvider>
  );
}
const ProtectedRoutes = () => {
  const auth = useAuth();
  // console.log("ProtectedRoutes", auth);
  // console.log("protected //", auth);
  return auth ? <Outlet /> : <Navigate to="/sign-in" />;
  // return auth ? <Navigate to="/dashboard" /> : <Outlet />;
};
const PublicRoutes = () => {
  // const value = 2;
  const dataRes = [];
  // const dataRes = [{ route: "/dashboard" }];
  // if (value === 0) {
  const auth = useAuth();
  // return auth ? <Navigate to="/dashboard" /> : <Outlet />;

  if (auth) {
    const userToken = localStorage.getItem(USER_DATA_STORAGE);
    let userRoles = [];
    if (userToken !== null) {
      userRoles = JSON.parse(userToken).roles;
    }
    routesData.forEach((i, idx, arr) => {
      const isAvalaible = i.role
        .map((el1) => ({
          name: el1,
          match: userRoles.some((el2) => el2.roleName === el1),
        }))
        .some((x) => x.match === true);
      if (isAvalaible) {
        dataRes.push(i);
      }
      if (idx === arr.length - 1) {
        return dataRes;
      }
      return "success";
    });
    // console.log("public //", dataRes);
    //  }
  }
  return dataRes.length > 0 ? <Navigate to={dataRes[0].route} /> : <Outlet />;
};

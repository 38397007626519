// src/context/UserContext.js
import React, { createContext, useMemo, useState } from "react";
import PropTypes from "prop-types";

// Création du contexte
export const UserContext = createContext();

export const UserProvider = ({ propsDataConfig, children }) => {
  const [user, setUser] = useState(propsDataConfig.selectedData);
  // const [user, setUser] = useState(propsDataConfig.selectedData);

  const updateUserDetails = (updatedDetails) => {
    setUser((prevUser) => ({ ...prevUser, ...updatedDetails }));
  };

  const updateUserRole = (newRole) => {
    setUser((prevUser) => ({ ...prevUser, role: newRole }));
  };
  const contextValue = useMemo(() => {
    const value = {
      user,
      niveau: propsDataConfig.niveau,
      roleTitle: propsDataConfig.roleTitle,
      updateUserDetails,
      updateUserRole,
      ApiService: propsDataConfig.ApiService,
      handleLoad: propsDataConfig.handleLoad,
    };
    return value;
  }, [user]);

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

// Validation des props avec PropTypes
UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
  propsDataConfig: PropTypes.object.isRequired,
};

import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  // TextField,
  // FormControl,
  DialogContentText,
  Input,
} from "@mui/material";
import { LieuContext } from "context/AppContext";
import DatePickerComponent from "components/DatePicker";
import * as XLSX from "xlsx";

const EffectifPrevForm = () => {
  const lieuContext = useContext(LieuContext);
  const { open, handleClose, saveData, listLieu, listEspece } = lieuContext;
  const [effectifValue, setEffectifValue] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isFileData, setisFileData] = useState(false);

  const handleSubmit = () => {
    console.log("Form Values:", effectifValue);
    if (Array.isArray(effectifValue) && effectifValue.length > 0) {
      saveData(effectifValue);
    } else {
      alert("Vérifier les données");
    }
  };
  const handleData = (lieuData, especeCount) => {
    const data = [];
    lieuData.forEach((departement, index) => {
      // const [departement, idDepartement] = i.split(",");
      const lieu = listLieu.find((x) => x.departement === departement);
      especeCount.forEach((count) => {
        const espece = listEspece.find((x) => x.nomCourant === count[0]);

        const item = {
          lieu,
          espece,
          effectifPrev: count[index + 1],
          startDate,
          endDate,
          // effectifPrevStartDate: "",
          // effectifPrevEndDate: "",
        };
        data.push(item);
      });
    });
    console.log("dddd", data);
    setEffectifValue(data);
  };
  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const binaryStr = event.target.result;
        const workbook = XLSX.read(binaryStr, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        if (Array.isArray(data) && data.length > 0) {
          // setExcelData(data);  // Update state with Excel data
          data.shift();
          // console.log("noHeaderArray", noHeaderArray);
          const [lieuData, ...especeData] = data;
          lieuData.shift();
          setisFileData(true);
          handleData(lieuData, especeData);
        }
      };
      reader.readAsBinaryString(file); // Read the file as binary
    }
  };
  useEffect(() => {}, []);
  const onDateChange = (event) => {
    console.log("e", event);
    const { value, name } = event.target;
    if (name === "effectifPrevStartDate") {
      setStartDate(value);
    }
    if (name === "effectifPrevEndDate") {
      setEndDate(value);
    }
  };
  const onDialogClose = () => {
    setStartDate(new Date());
    setEndDate(new Date());
    setEffectifValue([]);
    setisFileData(false);
    handleClose();
  };
  return (
    <div>
      <Dialog open={open} onClose={onDialogClose} fullWidth>
        <DialogTitle>Effectifs Prévisionnels</DialogTitle>
        <DialogContent style={{ height: "500px", paddingBottom: 100 }}>
          <DialogContentText> Campagne d&apos;identification</DialogContentText>
          <form /* onSubmit={handleSubmit} */>
            <DatePickerComponent
              label="Date de début"
              name="effectifPrevStartDate"
              dateValue={startDate}
              disabled={isFileData}
              handleDate={onDateChange}
            />

            <DatePickerComponent
              label="Date de fin"
              name="effectifPrevEndDate"
              dateValue={endDate}
              disabled={isFileData}
              handleDate={onDateChange}
            />
            <Input type="file" onChange={handleFileUpload} />
            {/* <Button variant="contained" color="primary" onClick={handleFileUpload}>
              Upload Excel
            </Button> */}
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDialogClose} color="secondary">
            Annuler
          </Button>
          <Button onClick={handleSubmit} type="submit" color="primary">
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EffectifPrevForm;

import { useState } from "react";
import apiUrl from "constant/apiUrl.ts";
// import apiUrl from "constant/apiUrl";
import axios from "axios";
import { ERROR } from "constant/message";
import { USER_DATA_STORAGE } from "constant";

export default function servicesAPI() {
  // const { handleLoad } = LoadingManager as { handleLoad: (value: loadObject) => void };
  // const loadingManager = LoadingManager();
  const [isLoading, setIsLoading] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");

  function handleLoad(value) {
    // console.log("dddd", value.isLoading);
    setIsAlert(value.isAlert);
    setMessage(value.message);
    setAlertSeverity(value.alertSeverity);
    // setIsLoading(false);
    setIsLoading(value.isLoading);
  }
  function handleError(error) {
    let errorMessage = error?.response?.data?.errorDetails ?? error?.message;
    if (error?.response?.status === 403) {
      errorMessage = "Une double connexion a été détectée. Veuillez vous reconnecter";
    } else if (error?.message === "Network Error") {
      errorMessage = "Vérifier votre connexion internet";
    } else if (error?.code === "ECONNABORTED") {
      errorMessage = "Votre connexion internet est instable ";
    } else if (errorMessage.includes("unique_num_tel_constraint")) {
      errorMessage = "Ce numéro de téléphone est attribué à un autre utilisateur";
    }
    console.log("ddd", errorMessage);
    console.log("error", error);
    const loadData = {
      isAlert: true,
      message: errorMessage,
      isLoading: false,
      alertSeverity: "error",
    };
    handleLoad(loadData);
  }
  // Récupération d'une liste de donnée en fonction de l'endpoint
  const getData = (endPointURL) => {
    let loadData = {
      isAlert: false,
      message: "",
      isLoading: false,
      alertSeverity: "",
    };
    let resultData = null;
    try {
      const userToken = localStorage.getItem(USER_DATA_STORAGE);
      if (userToken !== null) {
        const accessToken = JSON.parse(userToken);
        const config = {
          timeout: 10000,
          headers: { Authorization: `Bearer ${accessToken.access_token}` },
        };
        console.log("ddd", accessToken.access_token);
        loadData = { isAlert: false, message: "", isLoading: true, alertSeverity: "info" };
        handleLoad(loadData);
        resultData = axios
          .get(`${apiUrl}/${endPointURL}`, config)
          .then((response) => {
            const result = response.data;
            let returnData = null;
            // Si le résultat est un tableau de donnée
            // console.log("get data ", result);
            if (Array.isArray(result) && result.length > 0) {
              loadData = {
                isAlert: false,
                message: "",
                isLoading: false,
                alertSeverity: "success",
              };
              handleLoad(loadData);
              returnData = result;
            } else {
              loadData = {
                isAlert: true,
                message: "Aucune Données Trouvées",
                isLoading: false,
                alertSeverity: "error",
              };
              handleLoad(loadData);
              returnData = result;
            }

            return returnData;
          })
          .catch((error) => {
            console.log("error", endPointURL, error);
            handleError(error);
            return ERROR;
          });
      }
    } catch (error) {
      loadData = { isAlert: true, message: `${error}`, isLoading: false, alertSeverity: "error" };
      // loadingManager.handleLoad(loadData);
      handleLoad(loadData);
      return ERROR;
    }
    return resultData;
  };
  const getDataUser = (endPointURL) => {
    let loadData = {
      isAlert: false,
      message: "",
      isLoading: false,
      alertSeverity: "",
    };
    let resultData = null;
    try {
      const userToken = localStorage.getItem(USER_DATA_STORAGE);
      if (userToken !== null) {
        const accessToken = JSON.parse(userToken);
        const config = {
          timeout: 10000,
          headers: { Authorization: `Bearer ${accessToken.access_token}` },
        };
        // console.log("ddd", accessToken.access_token);
        loadData = { isAlert: false, message: "", isLoading: true, alertSeverity: "info" };
        handleLoad(loadData);
        resultData = axios
          .get(`${apiUrl}/${endPointURL}`, config)
          .then((response) => {
            const result = response.data;
            let returnData = null;
            // Si le résultat est un tableau de donnée
            // console.log("get data ", result);
            // f (Array.isArray(result) && result.length > 0) {
            loadData = {
              isAlert: false,
              message: "",
              isLoading: false,
              alertSeverity: "success",
            };
            handleLoad(loadData);
            returnData = result;

            return returnData;
          })
          .catch((error) => {
            handleError(error);
            return ERROR;
          });
      }
    } catch (error) {
      loadData = { isAlert: true, message: `${error}`, isLoading: false, alertSeverity: "error" };
      // loadingManager.handleLoad(loadData);
      handleLoad(loadData);
      return ERROR;
    }
    return resultData;
  };
  const getDataDashboard = (endPointURL) => {
    let loadData = {
      isAlert: false,
      message: "",
      isLoading: false,
      alertSeverity: "",
    };
    let resultData = null;
    try {
      const userToken = localStorage.getItem(USER_DATA_STORAGE);
      if (userToken !== null) {
        const accessToken = JSON.parse(userToken);
        const config = {
          timeout: 10000,
          headers: { Authorization: `Bearer ${accessToken.access_token}` },
        };
        console.log("ddd", accessToken.access_token);
        loadData = { isAlert: false, message: "", isLoading: true, alertSeverity: "info" };
        handleLoad(loadData);
        resultData = axios
          .get(`${apiUrl}/${endPointURL}`, config)
          .then((response) => {
            const result = response.data;
            let returnData = null;
            // Si le résultat est un tableau de donnée
            // console.log("get data ", result);
            // f (Array.isArray(result) && result.length > 0) {
            loadData = {
              isAlert: false,
              message: "",
              isLoading: false,
              alertSeverity: "success",
            };
            handleLoad(loadData);
            returnData = result;

            return returnData;
          })
          .catch((error) => {
            handleError(error);
            return ERROR;
          });
      }
    } catch (error) {
      loadData = { isAlert: true, message: `${error}`, isLoading: false, alertSeverity: "error" };
      // loadingManager.handleLoad(loadData);
      handleLoad(loadData);
      return ERROR;
    }
    return resultData;
  };
  // Enregistrement de donnée en fonction de l'endpoint
  const postData = (endPointURL, dataPost) => {
    let loadData = {
      isAlert: false,
      message: "",
      isLoading: false,
      alertSeverity: "",
    };
    let resultData = null;
    // console.log("in post", endPointURL);
    try {
      const userToken = localStorage.getItem(USER_DATA_STORAGE);
      if (userToken !== null) {
        const accessToken = JSON.parse(userToken);
        const config = {
          timeout: 10000,
          headers: { Authorization: `Bearer ${accessToken.access_token}` },
        };
        // console.log("dd", accessToken.access_token);
        loadData = { isAlert: false, message: "", isLoading: true, alertSeverity: "info" };
        handleLoad(loadData);
        resultData = axios
          .post(`${apiUrl}/${endPointURL}`, dataPost, config)
          .then((response) => {
            const result = response.data;
            /* let returnData = null;
            // Si le résultat est un tableau de donnée
            if (Array.isArray(result) && result.length > 0) {
              loadData = {
                isAlert: false,
                message: "",
                isLoading: false,
                alertSeverity: "success",
              };
              handleLoad(loadData);
              returnData = result;
            } else {
              loadData = {
                isAlert: true,
                message: "Aucune Données Trouvées",
                isLoading: false,
                alertSeverity: "error",
              };
              handleLoad(loadData);
              returnData = "noData";
            }

            return returnData; */
            // console.log("postdata", result);
            loadData = { isAlert: false, message: "", isLoading: false, alertSeverity: "info" };
            handleLoad(loadData);

            return result;
          })
          .catch((error) => {
            handleError(error);
            return ERROR;
          });
      }
    } catch (error) {
      loadData = { isAlert: true, message: `${error}`, isLoading: false, alertSeverity: "error" };
      // loadingManager.handleLoad(loadData);
      handleLoad(loadData);
      return ERROR;
    }
    return resultData;
  };
  const putData = (endPointURL, dataPost) => {
    let loadData = {
      isAlert: false,
      message: "",
      isLoading: false,
      alertSeverity: "",
    };
    let resultData = null;
    // console.log("in post", endPointURL);
    try {
      const userToken = localStorage.getItem(USER_DATA_STORAGE);
      if (userToken !== null) {
        const accessToken = JSON.parse(userToken);
        const config = {
          timeout: 10000,
          headers: { Authorization: `Bearer ${accessToken.access_token}` },
        };
        // console.log("dd", accessToken.access_token);
        loadData = { isAlert: false, message: "", isLoading: true, alertSeverity: "info" };
        handleLoad(loadData);
        resultData = axios
          .put(`${apiUrl}/${endPointURL}`, dataPost, config)
          .then((response) => {
            const result = response.data;
            /* let returnData = null;
            // Si le résultat est un tableau de donnée
            if (Array.isArray(result) && result.length > 0) {
              loadData = {
                isAlert: false,
                message: "",
                isLoading: false,
                alertSeverity: "success",
              };
              handleLoad(loadData);
              returnData = result;
            } else {
              loadData = {
                isAlert: true,
                message: "Aucune Données Trouvées",
                isLoading: false,
                alertSeverity: "error",
              };
              handleLoad(loadData);
              returnData = "noData";
            }

            return returnData; */
            loadData = { isAlert: false, message: "", isLoading: false, alertSeverity: "info" };
            handleLoad(loadData);

            return result;
          })
          .catch((error) => {
            handleError(error);
            return ERROR;
          });
      }
    } catch (error) {
      loadData = { isAlert: true, message: `${error}`, isLoading: false, alertSeverity: "error" };
      // loadingManager.handleLoad(loadData);
      handleLoad(loadData);
      return ERROR;
    }
    return resultData;
  };
  return {
    getData,
    isLoading,
    isAlert,
    message,
    alertSeverity,
    handleLoad,
    postData,
    getDataUser,
    getDataDashboard,
    putData,
  };
}

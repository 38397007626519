const dataStatutDemandeIdentification = [
  { label: "Initialisé", name: "INITIALIZED", bgColor: "gray" },
  { label: "Accepté", name: "ACCEPTED", bgColor: "blue" },
  { label: "Rejeté", name: "REJECTED", bgColor: "red" },
  { label: "Fait", name: "DONE", bgColor: "purple" },
  { label: "Validé", name: "VALIDATED", bgColor: "yellow" },
  { label: "Reclamé", name: "RECLAMED", bgColor: "orange" },
  { label: "Cloturé", name: "CLOSED", bgColor: "green" },
];
export default dataStatutDemandeIdentification;

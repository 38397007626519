export const normalizeText = (text) => {
  // console.log("sssss///", text);
  return (
    text
      ?.normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase() || ""
  );
};

export const tableCellTextFormat = (text, length) => {
  return text?.length >= length ? `${text.substring(0, length)}...` : text || "";
};

// Material Dashboard 2 React layouts
import { lazy, Suspense } from "react";
// import Dashboard from "layouts/dashboard";

// import Logout from "layouts/authentication/sing-out";
// import Users from "layouts/gestionUsers";
// import GestionUsers from "layouts/gestionUsers/rubrique";

// import Reproduction from "layouts/gestionReproduction";

// @mui icons
import Icon from "@mui/material/Icon";
import { /* MonitorHeart, */ Settings, FindInPage } from "@mui/icons-material";

// import ModuleIndexTable from "layouts/indexTable";
import {
  PATH_USER,
  PATH_DONNEE_REF,
  PATH_ROLE,
  MODULE_ESPECE,
  PATH_ESPECE,
  PATH_DASHBOARD_ELEVAGE,
  KEY_DASHBOARD_ELEVAGE,
  MODULE_PROFIL,
  KEY_PROFIL,
  PATH_PROFIL,
  PATH_FERME,
  KEY_FERME,
  PATH_CAMPAGNE,
  KEY_CAMPAGNE,
  /* KEY_DIRECTION,
  PATH_DIRECTION,
  PATH_STRUCTURE_PRIVEE,
  KEY_TAG,
  PATH_TAG, */
  PATH_USER_N1,
  KEY_USER_N1,
  PATH_USER_N2,
  KEY_USER_N2,
  PATH_USER_N3,
  KEY_USER_N3,
  PATH_USER_N4I,
  KEY_USER_N4I,
  PATH_USER_N4R,
  KEY_USER_N4R,
  PATH_USER_N5E,
  KEY_USER_N5E,
  PATH_USER_N5P,
  KEY_USER_N5P,
  KEY_LIEU,
  PATH_LIEU,
  KEY_DEMANDE_IDENTIFICATION,
  PATH_DEMANDE_IDENTIFICATION,
  PATH_IDENTIFICATION,
  KEY_IDENTIFICATION,
  KEY_SEARCH_USER,
  PATH_SEARCH_USER,
} from "constant/moduleInfo";
import {
  SUPER_ADMIN_ROLE,
  SUPERVISEUR_ROLE,
  ADMIN_PAYS_ROLE,
  ADMIN_DIRECTION_ROLE,
  // ADMIN_SOUS_SECTEUR_ROLE,
  ADMIN_REGION_ROLE,
  ADMIN_DEPARTEMENTAL_ROLE,
  RESPONSABLE_FERME,
} from "constant/role";
import SuspenceComponent from "components/SuspenceComponent";
import GestionLieu from "layouts/donneeReference/rubrique/gestionLieu";

// const Dashboard = lazy(() => import("layouts/dashboard"));
const Users = lazy(() => import("layouts/gestionUsers"));
const GestionUsers = lazy(() => import("layouts/gestionUsers/rubrique"));
// const NotAvailable = lazy(() => import("layouts/notAvailable"));
const Recherche = lazy(() => import("layouts/gestionAnimaux/recherche"));
const DemandeIdentification = lazy(() => import("layouts/gestionAnimaux/DemandeIdentification"));
const DonneesRef = lazy(() => import("layouts/donneeReference"));
const GestionRole = lazy(() => import("layouts/donneeReference/rubrique/gestionRole"));
const GestionEspece = lazy(() => import("layouts/donneeReference/rubrique/gestionEspece"));
const Dashboard = lazy(() => import("layouts/dashboard/rubrique"));
const GestionProfil = lazy(() => import("layouts/donneeReference/rubrique/gestionProfil"));
const GestionCampagne = lazy(() => import("layouts/donneeReference/rubrique/gestionCampagne"));
const GestionFerme = lazy(() => import("layouts/donneeReference/rubrique/gestionFerme"));
// const GestionEntite = lazy(() => import("layouts/donneeReference/rubrique/gestionEntite"));
// const GestionTag = lazy(() => import("layouts/donneeReference/rubrique/gestionTag"));
const SearchUsers = lazy(() => import("layouts/gestionUsers/searchUser"));
const routesData = [
  {
    type: "collapse",
    name: <span style={{ color: "black" }}>Dashboard</span>,
    key: KEY_DASHBOARD_ELEVAGE,
    icon: (
      <Icon fontSize="small" style={{ color: "black" }}>
        dashboard
      </Icon>
    ),
    route: PATH_DASHBOARD_ELEVAGE,
    component: (
      <Suspense fallback={<SuspenceComponent />}>
        <Dashboard />
      </Suspense>
    ),
    role: [
      SUPER_ADMIN_ROLE,
      SUPERVISEUR_ROLE,
      ADMIN_PAYS_ROLE,
      ADMIN_DIRECTION_ROLE,
      // ADMIN_SOUS_SECTEUR_ROLE,
      ADMIN_REGION_ROLE,
      ADMIN_DEPARTEMENTAL_ROLE,
      RESPONSABLE_FERME,
    ],
    collapse: [
      /* {
        name: "Analytics",
        key: "analytics",
        route: PATH_DASHBOARD,
        component: (
          <Suspense fallback={<SuspenceComponent />}>
            <Dashboard />
          </Suspense>
        ),
        collapse: [],
      },
      {
        name: <span style={{ color: "black" }}>{MODULE_DASHBOARD_ELEVAGE}</span>,
        key: "elevage",
        route: PATH_DASHBOARD_ELEVAGE,
        component: (
          <Suspense fallback={<SuspenceComponent />}>
            <DashboardCategorie />
          </Suspense>
        ),
        collapse: [],
      },
      {
        name: <span style={{ color: "black" }}>{MODULE_DASHBOARD_COMPAGNIE}</span>,
        key: "compagnie",
        route: PATH_DASHBOARD_COMPAGNIE,
        component: (
          <Suspense fallback={<SuspenceComponent />}>
            <DashboardCategorie />
          </Suspense>
        ),
        collapse: [],
      },
      {
        name: <span style={{ color: "black" }}>{MODULE_DASHBOARD_TRANSHUMANCE}</span>,
        key: "transhumance",
        route: PATH_DASHBOARD_TRANSHUMANCE,
        component: (
          <Suspense fallback={<SuspenceComponent />}>
            <DashboardCategorie />
          </Suspense>
        ),
        collapse: [],
      }, */
    ],
  },
  {
    type: "collapse",
    name: <span style={{ color: "black" }}>Identification</span>,
    key: KEY_IDENTIFICATION,
    icon: <FindInPage fontSize="small" style={{ color: "black" }} />,
    route: PATH_IDENTIFICATION,
    component: (
      <Suspense fallback={<SuspenceComponent />}>
        <Recherche />
      </Suspense>
    ),
    role: [
      SUPER_ADMIN_ROLE,
      SUPERVISEUR_ROLE,
      ADMIN_PAYS_ROLE,
      ADMIN_DIRECTION_ROLE,
      //  ADMIN_SOUS_SECTEUR_ROLE,
      ADMIN_REGION_ROLE,
      ADMIN_DEPARTEMENTAL_ROLE,
      RESPONSABLE_FERME,
    ],
    collapse: [
      {
        name: <span style={{ color: "black" }}>Animaux</span>,
        // key: "analytics",
        route: PATH_IDENTIFICATION,
        component: (
          <Suspense fallback={<SuspenceComponent />}>
            <Recherche />
          </Suspense>
        ),
        collapse: [],
      },
      /* {
        name: <span style={{ color: "black" }}>Animaux</span>,
        // key: "analytics",
        route: "/search",
        component: (
          <Suspense fallback={<SuspenceComponent />}>
            <Recherche />
          </Suspense>
        ),
        collapse: [],
      }, */
      {
        name: <span style={{ color: "black" }}>Demandes d&apos;identification</span>,
        key: KEY_DEMANDE_IDENTIFICATION,
        route: PATH_DEMANDE_IDENTIFICATION,
        component: (
          <Suspense fallback={<SuspenceComponent />}>
            <DemandeIdentification />
          </Suspense>
        ),
        collapse: [],
      },
    ],
  },
  {
    type: "collapse",
    name: <span style={{ color: "black" }}>Utilisateurs</span>,
    key: "utilisateurs",
    icon: (
      <Icon fontSize="small" style={{ color: "black" }}>
        peopleAltIcon
      </Icon>
    ),
    route: PATH_USER,
    component: (
      <Suspense fallback={<SuspenceComponent />}>
        <Users />
      </Suspense>
    ),
    role: [
      SUPER_ADMIN_ROLE,
      SUPERVISEUR_ROLE,
      ADMIN_PAYS_ROLE,
      ADMIN_DIRECTION_ROLE,
      // ADMIN_SOUS_SECTEUR_ROLE,
      ADMIN_REGION_ROLE,
      ADMIN_DEPARTEMENTAL_ROLE,
      RESPONSABLE_FERME,
    ],
    collapse: [
      {
        name: "Analytics",
        key: "analytics",
        route: PATH_USER,
        component: (
          <Suspense fallback={<SuspenceComponent />}>
            <Users />
          </Suspense>
        ),
        collapse: [],
      },
      {
        name: <span style={{ color: "black" }}>Admins Nationaux</span>,
        key: KEY_USER_N1,
        route: PATH_USER_N1,
        component: (
          <Suspense fallback={<SuspenceComponent />}>
            <GestionUsers />
          </Suspense>
        ),
        collapse: [],
      },
      {
        name: <span style={{ color: "black" }}>Admins Regionaux</span>,
        key: KEY_USER_N2,
        route: PATH_USER_N2,
        component: (
          <Suspense fallback={<SuspenceComponent />}>
            <GestionUsers />
          </Suspense>
        ),
        collapse: [],
      },
      {
        name: <span style={{ color: "black" }}>Admins Départementaux</span>,
        key: KEY_USER_N3,
        route: PATH_USER_N3,
        component: (
          <Suspense fallback={<SuspenceComponent />}>
            <GestionUsers />
          </Suspense>
        ),
        collapse: [],
      },
      {
        name: <span style={{ color: "black" }}>Agents Identificateurs</span>,
        key: KEY_USER_N4I,
        route: PATH_USER_N4I,
        component: (
          <Suspense fallback={<SuspenceComponent />}>
            <GestionUsers />
          </Suspense>
        ),
        collapse: [],
      },
      {
        name: <span style={{ color: "black" }}>Responsables Exploitation </span>,
        key: KEY_USER_N4R,
        route: PATH_USER_N4R,
        component: (
          <Suspense fallback={<SuspenceComponent />}>
            <GestionUsers />
          </Suspense>
        ),
        collapse: [],
      },
      {
        name: <span style={{ color: "black" }}>Eleveurs </span>,
        key: KEY_USER_N5E,
        route: PATH_USER_N5E,
        component: (
          <Suspense fallback={<SuspenceComponent />}>
            <GestionUsers />
          </Suspense>
        ),
        collapse: [],
      },
      {
        name: <span style={{ color: "black" }}>Propriétaires </span>,
        key: KEY_USER_N5P,
        route: PATH_USER_N5P,
        component: (
          <Suspense fallback={<SuspenceComponent />}>
            <GestionUsers />
          </Suspense>
        ),
        collapse: [],
      },
      {
        name: <span style={{ color: "black" }}>Rechercher un utilisateur </span>,
        key: KEY_SEARCH_USER,
        route: PATH_SEARCH_USER,
        component: (
          <Suspense fallback={<SuspenceComponent />}>
            <SearchUsers />
          </Suspense>
        ),
        collapse: [],
      },
    ],
  },
  {
    type: "collapse",
    name: <span style={{ color: "black" }}>Données de Référence</span>,
    key: "donnees_ref",
    icon: <Settings fontSize="small" style={{ color: "black" }} />,
    route: PATH_DONNEE_REF,
    component: (
      <Suspense fallback={<SuspenceComponent />}>
        <DonneesRef />
      </Suspense>
    ),
    role: [
      SUPER_ADMIN_ROLE,
      SUPERVISEUR_ROLE,
      ADMIN_PAYS_ROLE,
      ADMIN_DIRECTION_ROLE,
      // ADMIN_SOUS_SECTEUR_ROLE,
      ADMIN_REGION_ROLE,
      ADMIN_DEPARTEMENTAL_ROLE,
      RESPONSABLE_FERME,
    ],
    collapse: [
      {
        name: "Analytics",
        key: "analytics",
        route: PATH_DONNEE_REF,
        component: (
          <Suspense fallback={<SuspenceComponent />}>
            <DonneesRef />
          </Suspense>
        ),
        collapse: [],
      },
      {
        name: <span style={{ color: "black" }}>Opérations</span>,
        key: KEY_CAMPAGNE,
        route: PATH_CAMPAGNE,
        component: (
          <Suspense fallback={<SuspenceComponent />}>
            <GestionCampagne />
          </Suspense>
        ),
        collapse: [],
      },
      {
        name: <span style={{ color: "black" }}>Exploitations</span>,
        key: KEY_FERME,
        route: PATH_FERME,
        component: (
          <Suspense fallback={<SuspenceComponent />}>
            <GestionFerme />
          </Suspense>
        ),
        collapse: [],
      },
      {
        name: <span style={{ color: "black" }}>Fonctions</span>,
        key: "role",
        route: PATH_ROLE,
        component: (
          <Suspense fallback={<SuspenceComponent />}>
            <GestionRole />
          </Suspense>
        ),
        collapse: [],
      },
      {
        name: <span style={{ color: "black" }}>{MODULE_PROFIL}</span>,
        key: KEY_PROFIL,
        route: PATH_PROFIL,
        component: (
          <Suspense fallback={<SuspenceComponent />}>
            <GestionProfil />
          </Suspense>
        ),
        collapse: [],
      },
      {
        name: <span style={{ color: "black" }}>{MODULE_ESPECE}</span>,
        key: "especes",
        route: PATH_ESPECE,
        component: (
          <Suspense fallback={<SuspenceComponent />}>
            <GestionEspece />
          </Suspense>
        ),
        collapse: [],
      },
      /* {
        name: <span style={{ color: "black" }}>Directions</span>,
        key: KEY_DIRECTION,
        route: PATH_DIRECTION,
        component: (
          <Suspense fallback={<SuspenceComponent />}>
            <GestionEntite />
          </Suspense>
        ),
        collapse: [],
      },
      {
        name: <span style={{ color: "black" }}>Structures Privées</span>,
        // key: KEY_DIRECTION,
        route: PATH_STRUCTURE_PRIVEE,
        component: (
          <Suspense fallback={<SuspenceComponent />}>
            <GestionEntite />
          </Suspense>
        ),
        collapse: [],
      },
      {
        name: <span style={{ color: "black" }}>Projets</span>,
        key: KEY_TAG,
        route: PATH_TAG,
        component: (
          <Suspense fallback={<SuspenceComponent />}>
            <GestionTag />
          </Suspense>
        ),
        collapse: [],
      }, */
      {
        name: <span style={{ color: "black" }}>Lieux</span>,
        key: KEY_LIEU,
        route: PATH_LIEU,
        component: (
          <Suspense fallback={<SuspenceComponent />}>
            <GestionLieu />
          </Suspense>
        ),
        collapse: [],
      },
      /* {
        name: <span style={{ color: "black" }}>{MODULE_MALADIE}</span>,
        key: "maladies",
        route: PATH_MALADIE,
        component: (
          <Suspense fallback={<SuspenceComponent />}>
            <NotAvailable />
          </Suspense>
        ),
        collapse: [],
      },
      {
        name: <span style={{ color: "black" }}>{MODULE_VACCIN}</span>,
        key: "vaccins",
        route: PATH_VACCIN,
        component: (
          <Suspense fallback={<SuspenceComponent />}>
            <NotAvailable />
          </Suspense>
        ),
        collapse: [],
      }, */
    ],
  },
  /* {
    type: "collapse",
    name: <span style={{ color: "black" }}>Santé Animale</span>,
    key: "sante_animal",
    icon: <HealthAndSafety fontSize="small" style={{ color: "black" }} />,
    route: "/sante_animal",
    component: (
      <Suspense fallback={<SuspenceComponent />}>
        <NotAvailable />
      </Suspense>
    ),
    role: [
      SUPER_ADMIN_ROLE,
      SUPERVISEUR_ROLE,
      ADMIN_PAYS_ROLE,
      ADMIN_DIRECTION_ROLE,
      ADMIN_SOUS_SECTEUR_ROLE,
      ADMIN_REGION_ROLE,
      ADMIN_DEPARTEMENTAL_ROLE,
      RESPONSABLE_FERME,
    ],
    collapse: [
      {
        name: "Analytics",
        key: "analytics",
        route: "/sante_animal",
        component: (
          <Suspense fallback={<SuspenceComponent />}>
            <NotAvailable />
          </Suspense>
        ),
        collapse: [],
      },
      {
        name: "Maladies Animales Prioritaires",
        key: "maladies",
        route: "/sante_animal/maladies",
        component: (
          <Suspense fallback={<SuspenceComponent />}>
            <NotAvailable />
          </Suspense>
        ),
        collapse: [],
      },
      {
        name: "Diagnostics Différentiels",
        key: "diagnostic",
        route: "/sante_animal/diagnostic",
        component: (
          <Suspense fallback={<SuspenceComponent />}>
            <NotAvailable />
          </Suspense>
        ),
        collapse: [],
      },
      {
        name: "Medicaments",
        key: "medicaments",
        route: "/sante_animal/medicaments",
        component: (
          <Suspense fallback={<SuspenceComponent />}>
            <NotAvailable />
          </Suspense>
        ),
        collapse: [],
      },
      {
        name: "Vaccins",
        key: "vaccins",
        route: "/sante_animal/vaccins",
        component: (
          <Suspense fallback={<SuspenceComponent />}>
            <NotAvailable />
          </Suspense>
        ),
        collapse: [],
      },
    ],
  },
  {
    type: "collapse",
    name: <span style={{ color: "black" }}>Couverture Vaccinale</span>,
    key: "couvVaccin",
    icon: <Vaccines fontSize="small" style={{ color: "black" }} />,
    route: "/couvVaccin",
    component: (
      <Suspense fallback={<SuspenceComponent />}>
        <NotAvailable />
      </Suspense>
    ),
    role: [
      SUPER_ADMIN_ROLE,
      SUPERVISEUR_ROLE,
      ADMIN_PAYS_ROLE,
      ADMIN_DIRECTION_ROLE,
      ADMIN_SOUS_SECTEUR_ROLE,
      ADMIN_REGION_ROLE,
      ADMIN_DEPARTEMENTAL_ROLE,
      RESPONSABLE_FERME,
    ],
    collapse: [],
  }, */
];

export default routesData;
export const routesDataArray = [
  {
    name: "Dashboard",
    role: [
      SUPER_ADMIN_ROLE,
      SUPERVISEUR_ROLE,
      ADMIN_PAYS_ROLE,
      ADMIN_DIRECTION_ROLE,
      // ADMIN_SOUS_SECTEUR_ROLE,
      ADMIN_REGION_ROLE,
      ADMIN_DEPARTEMENTAL_ROLE,
    ],
  },
  {
    name: "Recherche",
    role: [
      SUPER_ADMIN_ROLE,
      SUPERVISEUR_ROLE,
      ADMIN_PAYS_ROLE,
      ADMIN_DIRECTION_ROLE,
      // ADMIN_SOUS_SECTEUR_ROLE,
      ADMIN_REGION_ROLE,
      ADMIN_DEPARTEMENTAL_ROLE,
    ],
  },
  {
    name: "Utilisateur",
    role: [
      SUPER_ADMIN_ROLE,
      SUPERVISEUR_ROLE,
      ADMIN_PAYS_ROLE,
      ADMIN_DIRECTION_ROLE,
      // ADMIN_SOUS_SECTEUR_ROLE,
      ADMIN_REGION_ROLE,
      ADMIN_DEPARTEMENTAL_ROLE,
    ],
  },
  {
    name: "Données de Référence",
    role: [
      SUPER_ADMIN_ROLE,
      SUPERVISEUR_ROLE,
      ADMIN_PAYS_ROLE,
      ADMIN_DIRECTION_ROLE,
      // ADMIN_SOUS_SECTEUR_ROLE,
      ADMIN_REGION_ROLE,
      ADMIN_DEPARTEMENTAL_ROLE,
    ],
  },
  {
    name: "Santé Animale",
    role: [
      SUPER_ADMIN_ROLE,
      SUPERVISEUR_ROLE,
      ADMIN_PAYS_ROLE,
      ADMIN_DIRECTION_ROLE,
      // ADMIN_SOUS_SECTEUR_ROLE,
      ADMIN_REGION_ROLE,
      ADMIN_DEPARTEMENTAL_ROLE,
    ],
  },
  {
    name: "Couverture Vaccinale",
    role: [
      SUPER_ADMIN_ROLE,
      SUPERVISEUR_ROLE,
      ADMIN_PAYS_ROLE,
      ADMIN_DIRECTION_ROLE,
      // ADMIN_SOUS_SECTEUR_ROLE,
      ADMIN_REGION_ROLE,
      ADMIN_DEPARTEMENTAL_ROLE,
    ],
  },
];
